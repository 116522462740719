import { getDefaultHeaders, setCsrfToken } from "./utils";

export async function createProject(data, myFiles, seriesListData) {
  let formData = new FormData();
  let check = false;
  let meta = {};

  const ext = {
    tiff: [".tiff", ".tif"],
    photoshop: [".psb", ".psd"],
    pdf: [".pdf"],
    png: [".png"],
    jpeg: [".jpeg", ".jpg"],
    webp: [".webp"],
  };
  const fileTypes = {
    tiff: "tiff",
    photoshop: "psb/psd",
    pdf: "pdf",
    png: "png",
    jpeg: "jpeg/jpg",
    webp: "webp",
  };

  Object.keys(data).map((key) => {
    if (key.startsWith("question")) {
      meta[key] = data[key];
    } else {
      if (key === "series") {
        seriesListData.forEach((obj) => {
          if (obj.title === data[key]) {
            formData.append(key, obj.id);
          }
        });
      } else if (key === "file_type") {
        formData.append(key, fileTypes[data[key].toLowerCase()]);
      } else {
        formData.append(key, data[key]);
      }
    }
  });

  formData.append("meta", meta);

  myFiles.forEach((file, idx) => {
    let temp = false;
    ext[data["file_type"].toLowerCase()].forEach((ex) => {
      if (file.path.toLowerCase().endsWith(ex)) {
        temp = true;
      }
    });
    if (!temp) {
      check = true;
    }
    formData.append(`uploaded_files`, file, file.name);
  });

  if (check) {
    return ["CustomError: Invalid FileType", ext[data["file_type"]]];
  }

  const requestOptions = {
    method: "POST",
    headers: await getDefaultHeaders(false, true),
    body: formData,
  };

  const url = "/api/comic-book-issues/";
  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    if (
      response.status === 403 &&
      jsonResponse.detail.includes("CSRF Failed")
    ) {
      await setCsrfToken();
      return await createProject(data, myFiles);
    }
    return jsonResponse;
  } catch (err) {
    console.log("ERROR 5", err);
    return false;
  }
}
