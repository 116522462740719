import { getDefaultHeaders } from "./utils";

export async function getPanelList(comicInputFileId) {
  let headers = await getDefaultHeaders();
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  const url = `/api/panels/?input_file_id=${comicInputFileId}`;
  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    jsonResponse.sort(function (a, b) {
      return a.panel_num - b.panel_num;
    });
    return jsonResponse;
  } catch (err) {
    console.log("ERROR 11", err);
    return [];
  }
}
