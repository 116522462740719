import { getDefaultHeaders } from "./utils";

export async function fetchUserProfile() {
  let headers = await getDefaultHeaders();
  const url = "/api/auth/user-profile/";

  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });

  const status = response.status;

  if (status === 401) {
    return "error";
  } else {
    const jsonResponse = await response.json();
    return jsonResponse[0];
  }
}