import { getDefaultHeaders } from "./utils";

export async function getFileType() {
  let headers = await getDefaultHeaders();

  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  const url = "/api/model-choices/filetype-choices/";

  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (err) {
    console.log("ERROR 10", err);
    return -1;
  }
}
