import { getDefaultHeaders, setCsrfToken } from "./utils";

export async function downloadPost(step, comicId, langCode = null) {
  let headers = await getDefaultHeaders(true, true);

  let payload = {};
  if (langCode) {
    payload = {
      language_codes: [langCode],
    };
  }

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  };
  const url = `/api/exports/${step}/${comicId}/`;
  try {
    const result = await fetch(url, requestOptions);

    if (result.status === 403) {
      const jsonResponse = await result.json();
      if (jsonResponse.detail.includes("CSRF Failed")) {
        await setCsrfToken();
        return await downloadPost(step, comicId, langCode);
      }
    }
    if (!result.ok) {
      throw Error(result.statusText);
    }

    const header = result.headers.get("Content-Disposition");
    const parts = header.split(";");
    const filename = parts[1].split("=")[1].replaceAll('"', "");

    const blob = await result.blob();

    if (blob != null) {
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
    return true;
  } catch (err) {
    console.log("ERROR 6", err);
    return false;
  }
}
