import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import InfoComponent from "../../components/InfoComponent";
import { useDispatch, useSelector } from "react-redux";
import { fetchPanelList } from "../../features/panels/panelsSlice";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Loader from "../../components/Loader";
import LanguageSelector from "../../components/LanguageSelector";
import {
  fetchcomicTitle,
  selectComicSubTitle,
  selectComicTitle,
  selectComicTitleStatus,
} from "../../features/comicTitle/comicTitleSlice";
import TranslationPanels from "../../components/TranslationPanels";
import { currentSelectedLanguage } from "../../features/selectedLanguage/languageSlice";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadPost } from "../../api/downloadPost";

const TranslationId = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [isUpdatedComicTitle, setIsUpdatedComicTitle] = useState(false);
  const lang = useSelector(currentSelectedLanguage);
  const panelStatus = useSelector((state) => state.panels.status);
  const comicTitleStatus = useSelector(selectComicTitleStatus);
  const comicSubTitle = useSelector(selectComicSubTitle);
  const comicTitle = useSelector(selectComicTitle);

  useEffect(() => {
    if (panelStatus === "idle" || !isUpdated) {
      dispatch(fetchPanelList(id));
      setIsUpdated(true);
    }
  }, [panelStatus, dispatch, isUpdated, id]);

  useEffect(() => {
    if (comicTitleStatus!=="loading" &&(comicTitleStatus === "idle" || !isUpdatedComicTitle)) {
      dispatch(fetchcomicTitle(id));
      setIsUpdatedComicTitle(true);
    }
  }, [comicTitleStatus, dispatch, id, isUpdatedComicTitle]);

  if (panelStatus !== "succeeded" && comicTitleStatus !== "succeeded") {
    return <Loader />;
  } else {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h6" mb="3px">
              Translation
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography variant="h4" fontWeight="bold">
                {comicTitle} / {comicSubTitle}
              </Typography>
              <InfoComponent />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <LanguageSelector />
            <Button
              variant="outlined"
              endIcon={<DownloadIcon />}
              size="small"
              sx={{ padding: "10px", borderRadius: "10px" }}
              disabled={isDisabled}
              onClick={() => {
                setIsDisabled(true);
                downloadPost("translation", id, lang).then((res) => {
                  setIsDisabled(false);
                });
              }}
            >
              Download Results
            </Button>
          </Box>
        </Box>
        <TranslationPanels />
      </>
    );
  }
};

export default TranslationId;
