import { getDefaultHeaders } from "./utils";

export async function getComicSeriesList() {
  let headers = await getDefaultHeaders();

  const requestOptions = {
    method: "GET",
    headers: headers,
    body: JSON.stringify(),
  };
  const url = "/api/comic-series/";

  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (err) {
    console.log("ERROR 9", err);
    return -1;
  }
}
