import React from "react";
import Header from "../../components/Header";
import HomeTable from "../../components/HomeTable";
import { Grid } from "@mui/material";

const Extraction = () => {
  return (
    <Grid container spacing={4} height="100%">
      <Grid item sm={12} md={12}>
        <Header title="Extraction" />
      </Grid>
      <Grid item sm={12} md={12}>
        <HomeTable sortData="Extraction"/>
      </Grid>
    </Grid>
  );
};

export default Extraction;
