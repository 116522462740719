import { getDefaultHeaders, setCsrfToken } from "./utils";

export async function panelPatchReview(id, dt) {
  let headers = await getDefaultHeaders(true, true);
  const requestOptions = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(dt),
  };
  const url = `/api/panels/${id}/`;
  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    if (response.status === 403 && jsonResponse.detail.includes("CSRF Failed")) {

      await setCsrfToken();
      return await panelPatchReview(id, dt)
    }
    return jsonResponse;
  } catch (err) {
    console.log("ERROR 15", err);
    return [];
  }
}
