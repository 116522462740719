import { auth } from "../firebaseConfig";

export const setCsrfToken = () => fetch("/ensure-csrf/", { method: "GET" });

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function getCsrfToken() {
  const csrftoken = getCookie("csrftoken");
  if (csrftoken === null) {
    setCsrfToken().then((res) => {
      const csrftoken = getCookie("csrftoken");
      return csrftoken;
    });
  } else {
    return csrftoken;
  }
}

export function getDefaultHeaders(setContentType = false, setCsrf = false) {
  let headers = new Headers();

  if (setContentType) {
    headers.set("Content-Type", "application/json");
  }
  if (setCsrf) {
    headers.set("X-CSRFToken", getCsrfToken());
  }
  const user = auth.currentUser;

  if (user) {
    const temp = user.getIdToken(true).then((accessToken) => {
      headers.set("Authorization", "Bearer: " + accessToken);
      return headers;
    });
    return temp;
  }
}
