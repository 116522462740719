import React, { useEffect, useState } from "react";
import { ReactTransliterate } from "../third-party/react-transliterate";

import {
  Box,
  Button,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import CheckIcon from "@mui/icons-material/Check";
import Suggestions from "./Suggestions";
import { useDispatch, useSelector } from "react-redux";
import { currentSelectedLanguage } from "../features/selectedLanguage/languageSlice";
import { getTranslatedText } from "../features/translatedText/translatedTextSlice";
import { translationPatch } from "../api/translationPatch";
import { fetchPanelList } from "../features/panels/panelsSlice";
import { useParams } from "react-router-dom";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
const TranslateComponent = ({ handleNext }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [smartKeyboard, setSmartKeyboard] = useState(true);
  const [isSuggestions, setIsSuggestion] = useState(true);

  const translatedTextData = useSelector(getTranslatedText);
  const lang = useSelector(currentSelectedLanguage);
  const [text, setText] = useState("");
  const [comicTextId, setComicTextId] = useState();
  const [suggest, setSuggest] = useState([]);

  useEffect(() => {
    translatedTextData.forEach((element) => {
      if (element.language === lang) {
        setComicTextId(element.id);
        setText(element.translation_final || "");
        setSuggest(element.translation_ai_options);
      }
    });
  }, [lang, translatedTextData]);

  const handleClick = (textOption) => {
    setText(textOption);
  };
  const handleQuickSubmit = (text) => {
    setText(text);
    handleSubmit(text);
  };

  const handleSubmit = async (quickText = null) => {
    const data = {
      translation_final: quickText || text,
    };
    translationPatch(data, comicTextId);
    dispatch(fetchPanelList(id));
    handleNext();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column-reverse",
        width: "100%",
        height: "100%",
        gap: "1rem",
        overflowY: "auto",
      }}
    >
      <Paper sx={{ border: "1px solid #737373", borderRadius: "10px" }}>
        <Box>
          {smartKeyboard ? (
            <ReactTransliterate
              renderComponent={(props) => {
                const inputRef = props.ref;
                delete props["ref"];
                return (
                  <TextField
                    autoFocus
                    multiline
                    fullWidth
                    rows={6}
                    variant="filled"
                    {...props}
                    inputRef={inputRef}
                    defaultValue={text}
                  />
                );
              }}
              value={text}
              onChangeText={(text) => {
                setText(text);
              }}
              lang={lang}
            />
          ) : (
            <TextField
              autoFocus
              multiline
              fullWidth
              rows={6}
              variant="filled"
              defaultValue={text}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: ".5rem 1rem",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                defaultChecked
                onChange={() => {
                  setSmartKeyboard(!smartKeyboard);
                }}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography>Smart Keyboard</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                defaultChecked
                onChange={() => {
                  setIsSuggestion(!isSuggestions);
                }}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography>Suggestions</Typography>
            </Stack>
          </Box>
          <Box>
            <Button
              variant="contained"
              endIcon={<CheckIcon />}
              sx={{ borderRadius: "50px" }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Paper>

      {isSuggestions && (
        <Paper
          sx={{
            borderRadius: "10px",
            overflowY: "auto",
          }}
        >
          <Suggestions
            data={suggest}
            handleClick={handleClick}
            handleQuickSubmit={handleQuickSubmit}
          />
        </Paper>
      )}
    </Box>
  );
};

export default TranslateComponent;
