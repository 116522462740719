import { getDefaultHeaders, setCsrfToken } from "./utils";

export async function comicTextDelete(comicTextId) {
  let headers = await getDefaultHeaders(false, true);
  const requestOptions = {
    method: "DELETE",
    headers: headers,
  };
  const url = `/api/comic-texts/${comicTextId}/`;
  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    if (response.status === 403 && jsonResponse.detail.includes("CSRF Failed")) {

      await setCsrfToken();
      return await comicTextDelete(comicTextId)
    }
    return jsonResponse;
  } catch (err) {
    console.log("ERROR 3", err);
    return [];
  }
}
