import { Grid } from "@mui/material";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import HomeTable from "../../components/HomeTable";
import { useState } from "react";
import { useEffect } from "react";
import { stageWiseStats } from "../../api/stageWiseStats";
import Loader from "../../components/Loader";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import QueuePlayNextOutlinedIcon from "@mui/icons-material/QueuePlayNextOutlined";
import HourglassTopOutlinedIcon from "@mui/icons-material/HourglassTopOutlined";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";

const HOME = () => {
  const [data, setData] = useState({});
  const [isFetched, setIsFetched] = useState(false);
  useEffect(() => {
    stageWiseStats().then((dt) => {
      setData(dt);
      setIsFetched(true);
    });
  }, []);

  if (isFetched) {
    return (
      <Grid container spacing={4} sx={{ height: "100%" }}>
        <Grid item xs={12} md={12}>
          <Header title="Home" />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={6}>
            <Grid item xs={6} md={3}>
              <StatBox
                statNumber={data["total_input_file"]}
                title="Total Comics"
                icon={<ImportContactsOutlinedIcon color="primary" />}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <StatBox
                statNumber={data["init"]}
                title="Not Started"
                icon={<QueuePlayNextOutlinedIcon color="primary" />}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <StatBox
                statNumber={data["text-extraction"] + data["ai-translation"]}
                title="In progress"
                icon={<HourglassTopOutlinedIcon color="primary" />}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <StatBox
                statNumber={data["output-generation"]}
                title="Completed"
                icon={<LibraryAddCheckOutlinedIcon color="primary" />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} sx={{ minHeight: "50vh" }}>
          <HomeTable createButton="true"></HomeTable>
        </Grid>
      </Grid>
    );
  } else {
    return <Loader />;
  }
};
export default HOME;
