import { getDefaultHeaders, setCsrfToken } from "./utils";

export async function feedbackPost(data, myFiles) {
  let formData = new FormData();
  Object.keys(data).map((key) => formData.append(key, data[key]));
  myFiles.forEach((file, idx) => {
    formData.append(`uploaded_files`, file, file.name);
  });
  const headers = getDefaultHeaders(false, true);
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: formData,
  };
  const url = "/api/feedback/";
  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    if (response.status === 403 && jsonResponse.detail.includes("CSRF Failed")) {
      
      await setCsrfToken();
      return await feedbackPost(data, myFiles)
    }
    return jsonResponse;
  } catch (err) {
    console.log("ERROR 7", err);
    return false;
  }
}
