import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPanelList } from "../../api/getPanelList";

const panelSlice = createSlice({
  name: "panels",
  initialState: {
    status: "idle",
    data: [],
    error: null,
    selectedTextIndex: null,
    selectedPageIndex: null,
    isCreateNew: false,
  },
  reducers: {
    toggleCreateNew: (state) => {
      state.isCreateNew = !state.isCreateNew;
    },
    updateIndex: (state, action) => {
      const { textIndex, pageIndex } = action.payload;
      state.selectedTextIndex = textIndex;
      state.selectedPageIndex = pageIndex;
    },
    resetIndex: (state) => {
      state.selectedTextIndex = null;
      state.selectedPageIndex = null;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPanelList.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPanelList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchPanelList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getPanelListStatus = (state) => state.panels.status;
export const selectPanelList = (state) => state.panels.data;
export const selectedTextCardIndex = (state) => state.panels.selectedTextIndex;
export const selectedPageCardIndex = (state) => state.panels.selectedPageIndex;
export const createNewStatus = (state) => state.panels.isCreateNew;
export const fetchPanelList = createAsyncThunk(
  "panels/fetchPanelList",
  async (id, thunkAPI) => {
    try {
      const response = await getPanelList(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const { updateIndex, resetIndex, setData, toggleCreateNew } =
  panelSlice.actions;

export default panelSlice.reducer;
