import { configureStore } from "@reduxjs/toolkit";
import panelReducer from "../features/panels/panelsSlice";
import homeTableReducer from "../features/home/homeTableSlice";
import selectedComicReducer from "../features/selectedComic/selectedComicSlice";
import selectedPageReducer from "../features/selectedPage/selectedPageSlice";
import comicTitleReducer from "../features/comicTitle/comicTitleSlice";
import languageReducer from "../features/selectedLanguage/languageSlice";
import translatedTextReducer from "../features/translatedText/translatedTextSlice";
import userAuthsReducer from "../features/userAuths/userAuthsSlice";

export default configureStore({
  reducer: {
    panels: panelReducer,
    homeTable: homeTableReducer,
    selectedComic: selectedComicReducer,
    selectedPage: selectedPageReducer,
    comicTitle: comicTitleReducer,
    selectedLanguage: languageReducer,
    translatedText: translatedTextReducer,
    userAuths: userAuthsReducer,
  },
});
