import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  selectComicDateCreated,
  selectComicDateModified,
  selectComicTitle,
  selectComicVolume,
} from "../features/comicTitle/comicTitleSlice";
import { useSelector } from "react-redux";

export default function InfoComponent({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const comicTitle = useSelector(selectComicTitle);
  const comicVolume = useSelector(selectComicVolume);
  const comicDateCreated = useSelector(selectComicDateCreated);
  const comicDateModified = useSelector(selectComicDateModified);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Tooltip title="Info">
        <IconButton
          aria-label="delete"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          color="primary"
        >
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ minWidth: "250px", padding: "15px" }}>
          <Stack spacing={2}>
            <Box>
              <Typography variant="caption" color="grey">
                Title
              </Typography>
              <Typography variant="h6">{comicTitle}</Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="grey">
                Volume
              </Typography>
              <Typography variant="h6">{comicVolume}</Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="grey">
                Creation Date
              </Typography>
              <Typography variant="h6">{comicDateCreated}</Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="grey">
                Last Updation Date
              </Typography>
              <Typography variant="h6">{comicDateModified}</Typography>
            </Box>
          </Stack>
        </Box>
      </Popover>
    </div>
  );
}
