import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { translatedText } from "../../api/translatedText";

const translatedTextSlice = createSlice({
  name: "translatedText",
  initialState: {
    status: "idle",
    data: [],
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTranslatedText.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTranslatedText.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchTranslatedText.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getTranslatedText = (state) => state.translatedText.data;
export const getTranslatedTextStatus = (state) => state.translatedText.status;
export const fetchTranslatedText = createAsyncThunk(
  "translatedText/fetchTranslatedText",
  async (id, thunkAPI) => {
    try {
      const response = await translatedText(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export default translatedTextSlice.reducer;
