import { getDefaultHeaders, setCsrfToken } from "./utils";

export async function submitJobPost(stage, file_id) {
  let headers = await getDefaultHeaders(true, true);
  const requestOptions = {
    method: "POST",
    headers: headers,
  };
  const url = `/api/submit-job/${stage}/${file_id}/`;
  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    if (response.status === 403 && jsonResponse.detail.includes("CSRF Failed")) {

      await setCsrfToken();
      return await submitJobPost(stage, file_id)
    }
    return jsonResponse;
  } catch (err) {
    console.log("ERROR 17", err);
    return [];
  }
}
