import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { homeTableRowData } from "../../api/homeTableRowData";

const comicTitleSlice = createSlice({
  name: "comicTitle",
  initialState: {
    status: "idle",
    error: null,
    title: "",
    subTitle: "",
    date_created: "",
    date_modified: "",
    volume: "",
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchcomicTitle.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchcomicTitle.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = "succeeded";
          state.title = action.payload.series.title;
          state.subTitle = action.payload.title;
          state.date_created = action.payload.datetime_created.slice(0, 10);
          state.date_modified = action.payload.datetime_modified.slice(0, 10);
          state.volume = action.payload.volume;
        } else {
          state.status = "failed";
        }
      })
      .addCase(fetchcomicTitle.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectComicTitle = (state) => state.comicTitle.title;
export const selectComicSubTitle = (state) => state.comicTitle.subTitle;
export const selectComicVolume = (state) => state.comicTitle.volume;
export const selectComicDateCreated = (state) => state.comicTitle.date_created;
export const selectComicDateModified = (state) =>
  state.comicTitle.date_modified;

export const selectComicTitleStatus = (state) => state.comicTitle.status;

export const fetchcomicTitle = createAsyncThunk(
  "comicTitle/fetchcomicTitle",
  async (id, thunkAPI) => {
    try {
      const response = await homeTableRowData(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export default comicTitleSlice.reducer;
