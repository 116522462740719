import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUserProfile } from "../../api/fetchUserProfile";

const userAuthsSlice = createSlice({
  name: "userAuths",
  initialState: {
    userProfileFetchState: "idle",
    isUserVerified: false,
    userUploadPermission: false,
    userExtractionPermission: false,
    userTranslationPermission: false,
    isUserSignedIn: null,
    userEmail: "",
    error: null,
  },
  reducers: {
    setSignOut: (state) => {
      state.isUserSignedIn = false;
      state.isUserVerified = false;
      state.userUploadPermission = false;
      state.userExtractionPermission = false;
      state.userTranslationPermission = false;
    },
    setSignIn: (state) => {
      state.isUserSignedIn = true;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserAuths.pending, (state, action) => {
        state.userProfileFetchState = "loading";
      })
      .addCase(fetchUserAuths.fulfilled, (state, action) => {
        state.userProfileFetchState = "succeeded";
        if (action.payload === "error") {
          state.isUserSignedIn = false;
        } else {
          state.isUserSignedIn = true;
          state.isUserVerified = action.payload["is_verified"];
          state.userUploadPermission = action.payload["has_upload_permission"];
          state.userExtractionPermission = action.payload["has_extraction_permission"];
          state.userTranslationPermission = action.payload["has_translation_permission"];
        }
      })
      .addCase(fetchUserAuths.rejected, (state, action) => {
        if (action.payload === 401 || action.payload === 403) {
          state.isUserSignedIn = false;
        }
        state.userProfileFetchState = "failed";
        state.error = action.error.message;
      });
  },
});

export const getUserProfileFetchStatus = (state) => state.userAuths.userProfileFetchState;

export const isUserVerified = (state) => state.userAuths.isUserVerified;
export const getUserUploadPermission = (state) => state.userAuths.userUploadPermission;
export const getUserExtractionPermission = (state) => state.userAuths.userExtractionPermission;
export const getUserTranslationPermission = (state) => state.userAuths.userTranslationPermission;


export const getIsUserSignedIn = (state) => state.userAuths.isUserSignedIn;
export const signedInUserEmail = (state) => state.userAuths.userEmail;


export const fetchUserAuths = createAsyncThunk(
  "userAuths/fetchUserAuths",
  async (id, thunkAPI) => {
    try {
      const response = await fetchUserProfile();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const { setSignOut, setSignIn, setUserEmail } = userAuthsSlice.actions;

export default userAuthsSlice.reducer;
