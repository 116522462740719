import { Box, Paper, Typography } from "@mui/material";

const StatBox = ({ statNumber, title, icon }) => {
  return (
    <Paper
      sx={{
        padding: "8% 10%",
        borderRadius: "10px",
        width: "100%",
      }}
    >
      <Box display="flex" justifyContent="space-between" mb="15px">
        <Typography variant="h3" fontWeight="bold">
          {statNumber}
        </Typography>
        <Paper
          elevation={0}
          sx={{
            width: "52px",
            height: "52px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </Paper>
      </Box>
      <Typography variant="h6">{title}</Typography>
    </Paper>
  );
};

export default StatBox;
