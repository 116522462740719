import React from "react";
import {
  // onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import {
  auth,
  // facebookProvider,
  googleProvider,
  // microsoftProvider,
} from "../../firebaseConfig";
import {
  Box, Button,
  Card,
  CardActions,
  CardContent,
  CardMedia, Stack, Typography
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getIsUserSignedIn,
  setSignIn,
  setUserEmail,
} from "../../features/userAuths/userAuthsSlice";
import { useDispatch, useSelector } from "react-redux";
import GoogleSVGIcon from "../../svg/GoogleSVGIcon";
// import MetaSVGIcon from "../../svg/MetaSVGIcon";
// import MicrosoftSVGIcon from "../../svg/MicrosoftSVGIcon";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserSignedIn = useSelector(getIsUserSignedIn);

  useEffect(() => {
    // signOut(auth)
    //   .then(() => {
    //     dispatch(setSignOut());
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     console.error("Error signing out:", error.message);
    //     setIsLoading(false);
    //   });
    if (isUserSignedIn) navigate("/");
  }, [isUserSignedIn, navigate]);

  const handleSignInWithGoogle = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const userEmail = result.user.email;
        dispatch(setUserEmail(userEmail));
        dispatch(setSignIn());
      })
      .catch((error) => {
        console.error("Google Sign-In Error:", error);
      });
  };

  // const handleSignInWithMicrosoft = () => {
  //   signInWithPopup(auth, microsoftProvider)
  //     .then((result) => {
  //       const user = result.user.email;
  //       dispatch(setUserEmail(user));
  //       dispatch(setSignIn());
  //     })
  //     .catch((error) => {
  //       console.error("Microsoft Sign-In Error:", error);
  //     });
  // };
  // const handleSignInWithMeta = () => {
  //   signInWithPopup(auth, facebookProvider)
  //     .then((result) => {
  //       const user = result.user.email;
  //       dispatch(setUserEmail(user));
  //       dispatch(setSignIn());
  //     })
  //     .catch((error) => {
  //       console.error("Meta Sign-In Error:", error);
  //     });
  // };

  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     navigate("/");
  //   } else {
  //     //Not signed In
  //   }
  // });


  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        '&::before': {
          zIndex: -1,
          content: "''",
          background: "url(https://wallpapers.com/images/hd/comic-book-background-a09lfl6egxpsqgwc.jpg) no-repeat 0 0;",
          opacity: 0.075,
          position: "absolute",
          height: "100vh",
          width: "100vw",
        }
      }}
    >
      <Card elevation={10} sx={{ maxWidth: "40vw", borderRadius: "10px" }}>
        <CardMedia
          sx={{
            height: "6rem",
            backgroundColor: "#fff",
            padding: "2rem 4rem",
          }}
          image="https://i0.wp.com/company.toonsutra.com/wp-content/uploads/2023/08/TOONSUTRA_TRANSPARANT-LOGO-01.png"
          title="toonsutra-logo"
        />
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "2rem",
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            Welcome to Comic Translation Tool
          </Typography>
        </CardContent>
        <CardActions sx={{ width: "100%", padding: "0rem 2rem 2rem 2rem" }}>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Button
              variant="contained"
              onClick={handleSignInWithGoogle}
              sx={{
                borderRadius: "20px",
                backgroundColor: (theme) => theme.palette.background.paper,
                color: (theme) => theme.palette.text.primary,
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.background.paper,
                  color: (theme) => theme.palette.text.primary,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "0.5rem",
                }}
              >
                <GoogleSVGIcon />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Sign in with Google
                </Box>
              </Box>
            </Button>
          </Stack>
        </CardActions>
      </Card>
    </Box>
  );
};

export default SignIn;