import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { homeTableData } from "../../api/homeTableData";

const homeTableSlice = createSlice({
  name: "homeTable",
  initialState: {
    status: "idle",
    data: [],
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchHomeTableList.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchHomeTableList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchHomeTableList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectHomeTableList = (state) => state.homeTable.data;
export const selectHomeTableStatus = (state) => state.homeTable.status;

export const fetchHomeTableList = createAsyncThunk(
  "homeTable/fetchHomeTableList",
  homeTableData
);

export default homeTableSlice.reducer;
