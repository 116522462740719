import React from "react";
import Header from "../../components/Header";
import HomeTable from "../../components/HomeTable";
import { Grid } from "@mui/material";

const Translation = () => {
  return (
    <Grid container spacing={4} height="100%">
      <Grid item sm={12} md={12}>
        <Header title="Translation" />
      </Grid>
      <Grid item sm={12} md={12}>
        <HomeTable sortData="Translation"/>
      </Grid>
    </Grid>
  );
};

export default Translation;
