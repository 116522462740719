import * as React from "react";
import {
  Box,
  DialogContent,
  TextField,
  Button,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";

const InnerForm = ({
  openInside,
  handleAddSeriesCancel,
  handleAddSeriesSubmit,
}) => {
  const { register, handleSubmit } = useForm({});
  return (
    <Dialog
      open={openInside}
      onClose={handleAddSeriesCancel}
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        elevation:4
      }}
    >
      <DialogTitle>Add a series</DialogTitle>
      <form onSubmit={handleSubmit(handleAddSeriesSubmit)}>
        <DialogContent>
          <TextField
            margin="dense"
            id="seriesName"
            label="Series Name"
            {...register("text", {
              required: "Please enter title",
            })}
            fullWidth
          />
          <TextField
            margin="dense"
            id="seriesDesc"
            label="Series Description"
            fullWidth
            multiline
            {...register("desc")}
            rows={2}
          />
          <Box
            sx={{
              display: "flex",
              pt: 2,
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Button
              onClick={handleAddSeriesCancel}
              variant="outlined"
              size="medium"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" size="medium">
              Submit
            </Button>
          </Box>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default InnerForm;
