import { getDefaultHeaders, setCsrfToken } from "./utils";

export async function comicTextsReorder(data, id) {
    let headers = await getDefaultHeaders(true, true);
    const requestOptions = {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(data),
    };
    const url = `/api/comic-texts-reorder/${id}/`;
    try {
        const response = await fetch(url, requestOptions);
        const jsonResponse = await response.json();
        if (response.status === 403 && jsonResponse.detail.includes("CSRF Failed")) {

            await setCsrfToken();
            return await comicTextsReorder(data, id)
        }
        return jsonResponse;
    } catch (err) {
        console.log("ERROR", err);
        return [];
    }
}
