import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box class="notfound-404">
        <Typography variant="h1">404</Typography>
      </Box>
      <h2>Oops! Page Not Found</h2>
      <p>
        Sorry but the page you are looking for does not exist or have been
        removed.
      </p>
      <Button
        onClick={() => {
          navigate("/");
        }}
      >
        Home Page
      </Button>
    </Box>
  );
};

export default ErrorPage;
