import { getDefaultHeaders } from "./utils";

export async function translatedText(comicTextId) {
  let headers = await getDefaultHeaders();
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  const url = `/api/translated-texts/?comic_text_id=${comicTextId}`;
  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (err) {
    console.log("ERROR 18", err);
    return -1;
  }
}