import * as React from "react";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Extarctionsvg from "../../svg/Extarctionsvg";
import Translationsvg from "../../svg/Translationsvg";
import Homesvg from "../../svg/Homesvg";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import LogoutIcon from "@mui/icons-material/Logout";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useState } from "react";
import { feedbackPost } from "../../api/feedbackPost";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import ClearIcon from "@mui/icons-material/Clear";
// import html2canvas from "html2canvas";
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseConfig";

import {
  getUserExtractionPermission,
  isUserVerified,
  setSignOut,
  getUserTranslationPermission,
} from "../../features/userAuths/userAuthsSlice";
import { useDispatch, useSelector } from "react-redux";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomSidebar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  // const { id } = useParams();
  const IsVerified = useSelector(isUserVerified);
  const isExtractionPemission = useSelector(getUserExtractionPermission);
  const isTranslationPemission = useSelector(getUserTranslationPermission);
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".png", ".jpg"],
    },
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const files = myFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes{" "}
      <Tooltip title="Clear">
        <IconButton variant="contained" size="small" onClick={removeFile(file)}>
          <ClearIcon sx={{ marginLeft: "0.5rem" }} />
        </IconButton>
      </Tooltip>
    </li>
  ));

  const handleSignoutClick = () => {
    signOut(auth)
      .then(() => {
        dispatch(setSignOut());
        navigate("/signin");
      })
      .catch((error) => {
        console.error("Error signing out:", error.message);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const checkPath = (link) => {
    return link === "/"
      ? window.location.pathname === link
      : window.location.pathname.startsWith(link);
  };

  const menuItems = [
    {
      title: "Home",
      icon: Homesvg,
      link: "/",
      access: IsVerified,
    },
    {
      title: "Extraction",
      icon: Extarctionsvg,
      link: "/extraction",
      access: isExtractionPemission,
    },
    {
      title: "Translation",
      icon: Translationsvg,
      link: "/translation",
      access: isTranslationPemission,
    },
    // {
    //   title: "Export",
    //   icon: "",
    //   link: "/export",
    // },
  ];

  const onSubmit = async (data) => {
    setIsLoading(true);
    // const stage =
    //   window.location.pathname === "/"
    //     ? "Home"
    //     : window.location.pathname.startsWith("/extraction")
    //     ? "Extraction"
    //     : "Translation";

    // const targetElement = document.documentElement;

    // html2canvas(targetElement).then((canvas) => {
    //   const screenshot = canvas.toDataURL("image/png");
    // const link = document.createElement("a");
    // link.href = screenshot;
    // link.download = "screenshot.png";
    // link.click();
    // });
    //loading wala issue he
    feedbackPost(data, myFiles).then((res) => {
      setIsLoading(false);
      setOpen(false);
    });
  };

  const handleFeedback = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper
      style={{
        position: "fixed",
        height: "100%",
        width: "75px",
        borderRadius: 0,
        padding: "1rem 0",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Tooltip title="Home">
              <IconButton
                disableRipple
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  alt="company-logo"
                  width="40px"
                  height="40px"
                  src={`https://pbs.twimg.com/profile_images/1371801440771993605/M6wcKlqe_400x400.jpg`}
                  style={{ borderRadius: "20%" }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider />
          {menuItems.map((item, index) => (
            <>
              {item.access && (
                <Box
                  sx={{
                    width: "100%",
                    height: "50px",
                    borderRight: checkPath(item.link)
                      ? "2px solid #ea5f18"
                      : "none",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title={item.title}>
                    <IconButton
                      disableRipple
                      onClick={() => {
                        navigate(item.link);
                      }}
                    >
                      <item.icon selected={checkPath(item.link)} />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </>
          ))}
        </Stack>
        <Stack
          spacing={2}
          sx={{
            width: "100%",
          }}
        >
          <Tooltip title="Feedback">
            <IconButton
              disableRipple
              onClick={handleFeedback}
              color={open ? "primary" : undefined}
            >
              <FeedbackIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout">
            <IconButton disableRipple onClick={handleSignoutClick}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.8)" } }}
          PaperProps={{
            sx: { borderRadius: "15px" },
            elevation: 2,
          }}
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h4" fontWeight="bold">
                Feedback
              </Typography>
            </Box>
          </DialogTitle>
          {!isLoading ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent dividers={true}>
                <TextField
                  required
                  name="username"
                  label="Username"
                  fullWidth
                  margin="dense"
                  {...register("username", {
                    required: "Please enter username",
                  })}
                  error={errors.username ? true : false}
                />
                <TextField
                  required
                  multiline
                  rows={4}
                  name="content"
                  label="Content"
                  fullWidth
                  margin="dense"
                  {...register("content", {
                    required: "Please enter content",
                  })}
                  error={errors.content ? true : false}
                />
                <section className="container">
                  <Paper
                    elevation={6}
                    {...getRootProps({ className: "dropzone" })}
                    style={{
                      width: "100%",
                      minHeight: "15vh",
                      borderRadius: "15px",
                      padding: "0 2rem",
                    }}
                  >
                    <input {...getInputProps()} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "2vw",
                      }}
                    >
                      <div
                        style={{
                          width: "75%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h3>Drop/Upload Screenshots</h3>
                        <p>Supported formats *.png *.jpg *.jpeg</p>
                      </div>
                      <div
                        style={{
                          width: "25%",
                          aspectRatio: "1",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px dashed #fff",
                          borderRadius: "50%",
                        }}
                      >
                        <CloudUploadOutlinedIcon />
                      </div>
                    </div>
                  </Paper>
                  <Stack spacing={5}>
                    {files.length > 0 && <h4>Files</h4>}
                    <ul>{files}</ul>
                  </Stack>
                </section>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ width: "15rem" }}
                  >
                    Submit
                  </Button>
                </Box>
              </DialogContent>
            </form>
          ) : (
            <DialogContent dividers={true}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "30vh",
                }}
              >
                <Stack spacing={2}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Uploading Files...
                  </Box>
                </Stack>
              </Box>
            </DialogContent>
          )}
        </Dialog>
      </Box>
    </Paper>
  );
};

export default CustomSidebar;
