import { Routes, Route, useNavigate } from "react-router-dom";
import HOME from "./pages/home";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import Translation from "./pages/translation";
import Extraction from "./pages/extraction";
import CustomSidebar from "./pages/global/CustomSidebar";
import ExtractionId from "./pages/extractionId";
import TranslationId from "./pages/translationId";
import { useEffect, useState } from "react";
import Auth from "./pages/auth";
import SignIn from "./pages/signin";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserExtractionPermission,
  fetchUserAuths,
  getIsUserSignedIn,
  isUserVerified,
  setSignIn,
  setSignOut,
  getUserTranslationPermission,
  getUserProfileFetchStatus,
  setUserEmail,
} from "./features/userAuths/userAuthsSlice";
import ErrorPage from "./pages/errorPage";
import { auth } from "./firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import store from "./app/store";
import Loader from "./components/Loader";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontSize: 12,
  },
});

onAuthStateChanged(auth, (user) => {
  const dispatch = store.dispatch;
  if (user) {
    user.getIdToken().then((token) => {
      dispatch({ type: setUserEmail.type, payload: user.email })
      dispatch({ type: setSignIn.type });
    });
  } else {
    dispatch({ type: setSignOut.type });
  }
});

function App() {
  const [loading, setLoading] = useState(true);

  const IsVerified = useSelector(isUserVerified);
  const isExtractionPemission = useSelector(getUserExtractionPermission);
  const isTranslationPemission = useSelector(getUserTranslationPermission);
  const isUserSignedIn = useSelector(getIsUserSignedIn);
  const userProfileFetchStatus = useSelector(getUserProfileFetchStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userProfileFetchStatus === "succeeded") {
      setLoading(false);
    } else if (userProfileFetchStatus === "failed") {
      navigate("/signin");
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [userProfileFetchStatus, navigate]);

  useEffect(() => {
    if (isUserSignedIn === null) {
      setLoading(true);
    } else if (isUserSignedIn === false) {
      navigate("/signin");
      setLoading(false);
    } else {
      setLoading(true);
      dispatch(fetchUserAuths());
    }
  }, [isUserSignedIn]);


  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {!loading ? (
        <div className="app">
          {IsVerified && <CustomSidebar />}
          <main
            className="content"
            style={{
              marginLeft: IsVerified ? "75px" : undefined,
              padding: "1.5rem",
              height: "99vh",
            }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  IsVerified ? <HOME /> : <Auth isUserVerified={IsVerified} />
                }
              />
              <Route
                path="/extraction"
                element={
                  IsVerified && isExtractionPemission ? (
                    <Extraction />
                  ) : (
                    <Auth isUserVerified={IsVerified} />
                  )
                }
              />
              <Route
                path="/extraction/:id"
                element={
                  IsVerified && isExtractionPemission ? (
                    <ExtractionId />
                  ) : (
                    <Auth isUserVerified={IsVerified} />
                  )
                }
              />
              <Route
                path="/translation"
                element={
                  IsVerified && isTranslationPemission ? (
                    <Translation />
                  ) : (
                    <Auth isUserVerified={IsVerified} />
                  )
                }
              />
              <Route
                path="/translation/:id"
                element={
                  IsVerified && isTranslationPemission ? (
                    <TranslationId />
                  ) : (
                    <Auth isUserVerified={IsVerified} />
                  )
                }
              />
              <Route path="/signin" element={<SignIn />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </main>
        </div>
      ) : (
        <div className="app">
          <Loader />
        </div>
      )}
    </ThemeProvider>
  );
}

export default App;