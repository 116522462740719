import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "selectedLanguage",
  initialState: {
    currLanguage: localStorage.getItem("selectedLanguage") || "hi",
  },
  reducers: {
    selectCurrLanguage: (state, action) => {
      state.currLanguage = action.payload;
      localStorage.setItem("selectedLanguage", action.payload);
    },
  },
});

export const currentSelectedLanguage = (state) =>
  state.selectedLanguage.currLanguage;
export const { selectCurrLanguage } = languageSlice.actions;
export default languageSlice.reducer;
