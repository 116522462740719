import { getDefaultHeaders } from "./utils";

const PROCESSING_STAGES = {
  "Stage Initialization": "INIT",
  "Text Extraction": "Extraction",
  "AI Translation": "Translation",
  "Output Generation": "Export",
};

const PROCESSING_STATES = {
  Queued: "Queued",
  "In Process": "In Process",
  "To Review": "Ready for Review",
  Reviewing: "Reviewing",
  Completed: "Completed",
  Error: "Error",
};

export async function homeTableData() {
  let data = [];
  let headers = await getDefaultHeaders();
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  const url = "/api/comic-input-files/";

  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    if (jsonResponse) {
      jsonResponse.map((el) => {
        let k = {
          id: el.id,
          title: el.comic_issue.series.title,
          issue: el.comic_issue.title,
          volume: el.comic_issue.volume,
          // episode: el.comic_issue.id,
          file_name: el.file_name,
          createdDate: el.datetime_created.slice(0, 10),
          stage: PROCESSING_STAGES[el.processing_stage],
          lastUpdated: el.datetime_modified.slice(0, 10),
          status: PROCESSING_STATES[el.processing_state],
        };
        data.push(k);
        return el;
      });
      return data;
    }
  } catch (err) {
    console.log("ERROR 12", err);
    return [];
  }
}
