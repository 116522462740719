import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { languageData } from "../api/languageData";
import { useDispatch, useSelector } from "react-redux";
import {
  currentSelectedLanguage,
  selectCurrLanguage,
} from "../features/selectedLanguage/languageSlice";
import { useState } from "react";
import { useEffect } from "react";

const StyledMenu = styled((props) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function LanguageSelector() {
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const lang = useSelector(currentSelectedLanguage);
  const [selected, setSelected] = useState("Select Language");
  const dispatch = useDispatch();

  useEffect(() => {
    languageData().then((res) => {
      setData(Object.entries(res));
      setSelected(res[lang]);
    });
  }, [lang]);

  const handleMenuItemClick = (event, index, key) => {
    setSelected(index);
    dispatch(selectCurrLanguage(key));
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        size="small"
        sx={{ padding: "10px", borderRadius: "10px", justifyContent: "space-between", minWidth: "100px" }}
      >
        <span style={{width: "100%"}}>{selected}</span>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {data.map((element) => (
          <MenuItem
            key={element[0]}
            selected={element[0] === selected}
            onClick={(event) =>
              handleMenuItemClick(event, element[1], element[0])
            }
          >
            {element[1]}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
