import React from "react";
import { Box, IconButton, Paper, TextField, Tooltip } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useForm } from "react-hook-form";
import TitleIcon from "@mui/icons-material/Title";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import useKeyboardShortcut from "use-keyboard-shortcut";
import {
  resetIndex,
  selectedPageCardIndex,
  selectedTextCardIndex,
} from "../features/panels/panelsSlice";
const TextCard = ({
  id,
  text,
  editable = true,
  onEdit,
  onSubmitClick,
  handleDelete,
  isFocused = false,
  textIndex,
  pageIndex,
}) => {
  const { register, handleSubmit } = useForm({});

  const selectedTextIndex = useSelector(selectedTextCardIndex);
  const selectedPageIndex = useSelector(selectedPageCardIndex);
  const dispatch = useDispatch();

  const { flushHeldKeys } = useKeyboardShortcut(
    ["Escape"],
    (shortcutKeys) => {
      if (textIndex != null && pageIndex != null && editable) {
        onHandleCross();
      }
    },
    {
      overrideSystem: false,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  );

  const onHandleCross = () => {
    dispatch(resetIndex());
    flushHeldKeys();
  };

  const onEditBtnClick = () => {
    onEdit();
  };

  const onSubmit = (data) => {
    const t = data.updatedText;
    onSubmitClick(t);
    dispatch(resetIndex());
  };

  return (
    <Paper
      elevation={1}
      sx={{
        padding: "0 0.2rem",
        ":hover": {
          border: "1px solid grey",
          cursor: "grab",
        },
        // Apply a border when isFocused is true
        ...(isFocused && {
          border: "1px solid grey", // You can customize the style to match the hover effect
        }),
      }}
    >
      <CardContent
        onDoubleClick={() =>
          editable &&
          selectedTextIndex === null &&
          selectedPageIndex === null &&
          onEditBtnClick()
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TitleIcon />
          {editable && (
            <CardActions>
              {textIndex !== selectedTextIndex ? (
                <Box>
                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={handleDelete}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton
                      color="primary"
                      onClick={onEditBtnClick}
                      disabled={
                        selectedTextIndex !== null && selectedPageIndex !== null
                      }
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={handleDelete}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              )}
            </CardActions>
          )}
        </div>
        {textIndex !== selectedTextIndex ? (
          <>{text}</>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} onReset={onHandleCross}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                autoFocus
                hiddenLabel
                id="textEditField"
                variant="standard"
                fullWidth
                defaultValue={text}
                {...register("updatedText")}
              />
              <div>
                <Tooltip title="Close">
                  <IconButton type="reset" color="error">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Submit">
                  <IconButton type="submit" color="success">
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </form>
        )}
      </CardContent>
    </Paper>
  );
};

export default TextCard;
