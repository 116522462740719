import React from "react";

const Homesvg = ({selected}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
    >
      <g opacity="1">
        <path
          d="M4 13.815H10C10.55 13.815 11 13.3368 11 12.7523V4.25069C11 3.6662 10.55 3.18799 10 3.18799H4C3.45 3.18799 3 3.6662 3 4.25069V12.7523C3 13.3368 3.45 13.815 4 13.815ZM4 22.3166H10C10.55 22.3166 11 21.8384 11 21.2539V17.0031C11 16.4186 10.55 15.9404 10 15.9404H4C3.45 15.9404 3 16.4186 3 17.0031V21.2539C3 21.8384 3.45 22.3166 4 22.3166ZM14 22.3166H20C20.55 22.3166 21 21.8384 21 21.2539V12.7523C21 12.1678 20.55 11.6896 20 11.6896H14C13.45 11.6896 13 12.1678 13 12.7523V21.2539C13 21.8384 13.45 22.3166 14 22.3166ZM13 4.25069V8.50148C13 9.08597 13.45 9.56418 14 9.56418H20C20.55 9.56418 21 9.08597 21 8.50148V4.25069C21 3.6662 20.55 3.18799 20 3.18799H14C13.45 3.18799 13 3.6662 13 4.25069Z"
          fill={selected ? "#ea5f18" : "white"}
        />
      </g>
    </svg>
  );
};

export default Homesvg;
