import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import { signOut } from "firebase/auth";
import {
  setSignOut,
  signedInUserEmail,
} from "../../features/userAuths/userAuthsSlice";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";

const Auth = ({ isUserVerified = true }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userEmail = useSelector(signedInUserEmail);
  const handleClick = () => {
    signOut(auth)
      .then(() => {
        dispatch(setSignOut());
        navigate("/signin");
      })
      .catch((error) => {
        console.error("Error signing out:", error.message);
      });
  };
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isUserVerified ? (
        <Paper elevation={8} sx={{ padding: "1rem", borderRadius: "10px" }}>
          <Stack gap={2}  sx={{ padding: "1rem" }}>
            <Typography variant="h4">Access Denied</Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/");
              }}
              startIcon={<HomeIcon />}
            >
              Home
            </Button>
            <Button
              variant="contained"
              startIcon={<LogoutIcon />}
              onClick={handleClick}
            >
              Sign Out
            </Button>
          </Stack>
        </Paper>
      ) : (
        <Paper elevation={8} sx={{ padding: "1rem", borderRadius: "10px", maxWidth: "40vw" }}>
          <Stack gap={2} sx={{ padding: "1rem" }}>
            <Typography variant="h6">
              You account with email id: <b>{userEmail}</b> is not verified.
              <br /> Please contact{" "}
              <a href="mailto:ashwin@toonsutra.com">
                <b>ashwin@toonsutra.com</b>
              </a>{" "}
              for the verification of your account.
            </Typography>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "1rem" }}
            >
              <Button
                variant="contained"
                startIcon={<LogoutIcon />}
                onClick={handleClick}
              >
                Sign Out
              </Button>
            </Box>
          </Stack>
        </Paper>
      )}
    </Box>
  );
};

export default Auth;
