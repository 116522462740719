import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import TextCard from "./TextCard.jsx";

export const Card = ({
  id,
  text,
  index,
  pageIndex,
  moveCard,
  onEdit,
  onSubmitClick,
  handleDelete,
  isFocused,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 4;
      const hoverMiddleY2 =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) * 0.75;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY2) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  return (
    <div ref={ref} data-handler-id={handlerId}>
      <TextCard
        id={id}
        text={text}
        editable={true}
        onEdit={onEdit}
        onSubmitClick={(newText) => onSubmitClick(newText)}
        handleDelete={handleDelete}
        isFocused={isFocused}
        textIndex={index}
        pageIndex={pageIndex}
      />
    </div>
  );
};
