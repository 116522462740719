import * as React from "react";
import { useEffect, useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import InnerForm from "./InnerForm";

import {
  Box,
  DialogContent,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  Slide,
  IconButton,
  CircularProgress,
  Paper,
  Snackbar,
  Alert,
  Autocomplete,
  Stack,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

import { createProject } from "../api/createProject";
import { getComicSeriesList } from "../api/getComicSeriesList";
import { comicSeriesPost } from "../api/comicSeriesPost";
import { getFileType } from "../api/getFileType";
import { fetchHomeTableList } from "../features/home/homeTableSlice";
import { getUserUploadPermission } from "../features/userAuths/userAuthsSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QUESTIONS = {
  question_one: {
    question: "How would you describe the theme of the comic book?",
    placeholder: "Example: For Archies, you would write 'Comedy & Drama'",
  },
  question_two: {
    question: "What kind of language & tone is used in this comic?",
    placeholder:
      "Example: For Archies, you would write 'Simple & Casual, like how teenagers speak.'",
  },
};

export default function FormModal() {
  const [open, setOpen] = useState(false);
  const [openInside, setOpenInside] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [seriesList, setSeriesList] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showFileError, setShowFileError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const dispatch = useDispatch();
  const [seriesListData, setSeriesListData] = useState([]);
  const isUploadPermission = useSelector(getUserUploadPermission);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      setShowFileError(false);
      setErrorText("");
      return;
    }
    setShowFileError(false);
    setErrorText("");
  };

  const validationSchema = Yup.object().shape({
    series: Yup.string(),
    title: Yup.string(),
    description: Yup.string(),
    volume: Yup.string(),
    file_type: Yup.string(),
    ...Object.fromEntries(
      Object.keys(QUESTIONS).map((key) => [key, Yup.string()])
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const getSeriesData = async () => {
    let dt = await getComicSeriesList();
    setSeriesListData(dt);
    if (dt !== -1) {
      dt = dt.map((option) => option.title);
      setSeriesList(dt);
    }
    let fileTypesRes = await getFileType();
    if (fileTypesRes) {
      fileTypesRes = Object.entries(fileTypesRes).map(([key, val]) => val);
      setFileTypes(fileTypesRes);
    }
  };

  useEffect(() => {
    getSeriesData();
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    data.context_meta = {};
    Object.entries(QUESTIONS).forEach(([key, value]) => {
      data.context_meta[value.question] = data[key];
      delete data[key];
    });
    data.context_meta = JSON.stringify(data.context_meta);
    createProject(data, myFiles, seriesListData).then((res) => {
      setIsLoading(false);
      setOpen(false);
      if (res[0] === "CustomError: Invalid FileType") {
        let txt = "Upload files of supported formats: ";
        res[1].forEach((element) => {
          txt = txt.concat(element);
          txt = txt.concat(" ");
        });
        setErrorText(txt);
        setShowFileError(true);
      } else {
        dispatch(fetchHomeTableList());
      }
    });
  };

  const handleAddSeriesSubmit = async (data) => {
    let newData = {
      title: data.text,
      description: data.desc,
    };
    await comicSeriesPost(newData);
    setOpenInside(false);
    setOpen(true);
    getSeriesData();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });
  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const files = myFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes{" "}
      <Tooltip title="Delete">

      <IconButton variant="contained" size="small" onClick={removeFile(file)}>
        <ClearIcon />
      </IconButton>
      </Tooltip>
    </li>
  ));

  const handleAddSeries = () => {
    setOpenInside(true);
  };

  const handleAddSeriesCancel = () => {
    setOpenInside(false);
    setOpen(true);
  };

  const handleNext = () => {
    if (activeStep === 1) {
      setOpen(false);
      setActiveStep(0);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  if (!seriesList) {
    <p>Error</p>;
  } else {
    return (
      <div>
        {isUploadPermission && (
          <Button size="large" variant="contained" onClick={handleClickOpen}>
            <AddIcon />
            CREATE PROJECT
            <Snackbar
              open={showFileError}
              autoHideDuration={2500}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity="warning"
                sx={{ width: "100%" }}
              >
                {errorText}
              </Alert>
            </Snackbar>
          </Button>
        )}

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.8)" } }}
          PaperProps={{
            sx: { borderRadius: "15px" },
            elevation: 2,
          }}
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h4" fontWeight="bold">
                Create Project
              </Typography>
            </Box>
          </DialogTitle>
          {!isLoading ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent dividers={true}>
                {activeStep === 0 && (
                  <Stack spacing={1} px={3} py={2}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Autocomplete
                        disablePortal
                        id="series"
                        options={seriesList}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            autoFocus
                            required
                            fullWidth
                            label="Series"
                            {...register("series", {
                              required: "Please enter Series",
                            })}
                            error={errors.series}
                          />
                        )}
                      />
                      <Tooltip title="Add New Series">
                      <IconButton onClick={handleAddSeries} color="primary">
                        <AddIcon />
                      </IconButton>
                      </Tooltip>
                    </Box>
                    <Typography variant="inherit" color="textSecondary">
                      {errors.series?.message}
                    </Typography>

                    <TextField
                      required
                      name="title"
                      label="Comic Issue Title"
                      fullWidth
                      margin="dense"
                      {...register("title", {
                        required: "Please enter Title",
                      })}
                      error={errors.title ? true : false}
                    />
                    <Typography variant="inherit" color="textSecondary">
                      {errors.title?.message}
                    </Typography>

                    <TextField
                      name="description"
                      label="Comic Issue description"
                      multiline
                      rows={2}
                      fullWidth
                      margin="dense"
                      {...register("description")}
                      error={errors.description ? true : false}
                    />
                    <Typography variant="inherit" color="textSecondary">
                      {errors.description?.message}
                    </Typography>

                    <TextField
                      id="volume"
                      name="volume"
                      label="Volume"
                      fullWidth
                      margin="dense"
                      {...register("volume")}
                      error={errors.volume ? true : false}
                    />
                    <Typography variant="inherit" color="textSecondary">
                      {errors.volume?.message}
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="fileType"
                      options={fileTypes}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          fullWidth
                          label="File Type"
                          defaultValue=""
                          {...register("file_type", {
                            required: "Please enter File Type",
                          })}
                          error={errors.file_type}
                        />
                      )}
                    />

                    <section className="container">
                      <Paper
                        elevation={6}
                        {...getRootProps({ className: "dropzone" })}
                        sx={{
                          width: "100%",
                          minHeight: "15vh",
                          borderRadius: "15px",
                          padding: "0 2rem",
                        }}
                      >
                        <input {...getInputProps()} />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "2vw",
                          }}
                        >
                          <div
                            style={{
                              width: "75%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <h3>Drop/Upload Web-toons file</h3>
                          </div>
                          <div
                            style={{
                              width: "25%",
                              aspectRatio: "1",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px dashed #fff",
                              borderRadius: "50%",
                            }}
                          >
                            <CloudUploadOutlinedIcon fontSize="large" />
                          </div>
                        </div>
                      </Paper>
                      <Stack>
                        {files.length > 0 && <h4>Files</h4>}
                        <ul>{files}</ul>
                      </Stack>
                    </section>
                  </Stack>
                )}
                {activeStep === 1 && (
                  <Stack spacing={1} px={3} py={2}>
                    {Object.entries(QUESTIONS).map(([key, value]) => (
                      <TextField
                        id={key}
                        name={key}
                        label={value.question}
                        placeholder={value.placeholder}
                        fullWidth
                        multiline
                        rows={4}
                        margin="dense"
                        {...register(key)}
                        error={errors[key] ? true : false}
                      />
                    ))}
                  </Stack>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {activeStep === 1 && (
                    <div style={{ margin: "0 10px" }}>
                      <Button
                        variant="outlined"
                        onClick={handleBack}
                        size="medium"
                      >
                        Back
                      </Button>
                    </div>
                  )}
                  <div style={{ margin: "0 10px" }}>
                    {activeStep === 0 && (
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        size="medium"
                      >
                        Next
                      </Button>
                    )}
                    {activeStep === 1 && (
                      <Button variant="contained" type="submit" size="medium">
                        Submit
                      </Button>
                    )}
                  </div>
                </Box>
              </DialogContent>
            </form>
          ) : (
            <DialogContent dividers={true}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "30vh",
                }}
              >
                <Stack spacing={2}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Uploading Files...
                  </Box>
                </Stack>
              </Box>
            </DialogContent>
          )}
        </Dialog>

        {/* Dialog 2 */}

        <InnerForm
          handleAddSeriesSubmit={handleAddSeriesSubmit}
          handleAddSeriesCancel={handleAddSeriesCancel}
          openInside={openInside}
        />
      </div>
    );
  }
}
