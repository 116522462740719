import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import InfoComponent from "../../components/InfoComponent";
import Panels from "../../components/Panels";
import { useDispatch, useSelector } from "react-redux";
import { fetchPanelList } from "../../features/panels/panelsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Loader from "../../components/Loader";
import {
  fetchcomicTitle,
  selectComicSubTitle,
  selectComicTitle,
  selectComicTitleStatus,
} from "../../features/comicTitle/comicTitleSlice";
import { submitJobPost } from "../../api/submitJobPost";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadPost } from "../../api/downloadPost";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getUserTranslationPermission } from "../../features/userAuths/userAuthsSlice";

const ExtractionId = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isUpdatedComicTitle, setIsUpdatedComicTitle] = useState(false);
  const navigate = useNavigate();
  const panelStatus = useSelector((state) => state.panels.status);
  const TranslationPer = useSelector(getUserTranslationPermission);
  const comicTitleStatus = useSelector(selectComicTitleStatus);
  const comicSubTitle = useSelector(selectComicSubTitle);
  const comicTitle = useSelector(selectComicTitle);

  useEffect(() => {
    if (panelStatus !== "loading" && (panelStatus === "idle" || !isUpdated)) {
      dispatch(fetchPanelList(id));
      setIsUpdated(true);
    }
  }, [panelStatus, dispatch, id, isUpdated]);

  useEffect(() => {
    if (
      comicTitleStatus !== "loading" &&
      (comicTitleStatus === "idle" || !isUpdatedComicTitle)
    ) {
      dispatch(fetchcomicTitle(id));
      setIsUpdatedComicTitle(true);
    }
  }, [comicTitleStatus, dispatch, id, isUpdatedComicTitle]);

  const handleSubmit = async () => {
    await submitJobPost("ai-translation", id);
    navigate("/extraction");
  };

  if (panelStatus !== "succeeded" && comicTitleStatus !== "succeeded") {
    return <Loader />;
  } else {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h6" mb="3px">
              Extraction
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography variant="h4" fontWeight="bold">
                {comicTitle} / {comicSubTitle}
              </Typography>
              <InfoComponent />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Button
              variant="outlined"
              endIcon={<DownloadIcon />}
              size="small"
              sx={{
                padding: "10px",
                borderRadius: "10px",
              }}
              disabled={isDisabled}
              onClick={() => {
                setIsDisabled(true);
                downloadPost("extraction", id).then((res) => {
                  setIsDisabled(false);
                });
              }}
            >
              Download Results
            </Button>
            {TranslationPer && (
              <Button
                variant="outlined"
                endIcon={<SendIcon />}
                size="small"
                sx={{ padding: "10px", borderRadius: "10px" }}
                onClick={handleSubmit}
              >
                Submit for Translation
              </Button>
            )}
          </Box>
        </Box>
        <DndProvider backend={HTML5Backend}>
          <Panels />
        </DndProvider>
      </>
    );
  }
};

export default ExtractionId;
