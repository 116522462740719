import { getDefaultHeaders } from "./utils";

export async function homeTableRowData(id) {
  let headers = await getDefaultHeaders();
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  const url = `/api/comic-input-files/${id}/`;
  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    return jsonResponse.comic_issue;
  } catch (err) {
    console.log("ERROR 13", err);
    return [];
  }
}
