import { createSlice } from "@reduxjs/toolkit";

const selectedComicSlice = createSlice({
  name: "selectedComic",
  initialState: {
    isSelected: false,
    selectedComicId: null,
    selectedComicStage: "",
  },
  reducers: {
    select: (state, action) => {
      state.isSelected = true;
      state.selectedComicId = action.payload.id;
      state.selectedComicStage = action.payload.row.stage;
    },
  },
});

export const isComicSelected = (state) => state.selectedComic.isSelected;
export const selectedComicStage = (state) =>
  state.selectedComic.selectedComicStage;
export const selectedComicId = (state) => state.selectedComic.selectedComicId;

export const { select } = selectedComicSlice.actions;

export default selectedComicSlice.reducer;
