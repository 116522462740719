import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Alert,
  Box,
  IconButton,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import FormModal from "./FormModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHomeTableList,
  selectHomeTableList,
  selectHomeTableStatus,
} from "../features/home/homeTableSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import ExportPopUp from "./ExportPopUp";
import Loader from "./Loader";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function QuickSearchToolbar(props) {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h4" fontWeight="bold">
        Comic Issues
      </Typography>
      <Box
        display="flex"
        borderRadius="5px"
        border="1px solid #4b4b4b"
        width="30rem"
        padding=".4rem"
      >
        <Tooltip title="Search">

          <IconButton type="button">
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <InputBase
          sx={{ ml: 2, flex: 1 }}
          placeholder="Quick Search..."
          value={props.value}
          onChange={props.onChange}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <Tooltip title="Clear Input">
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: props.value ? "visible" : "hidden" }}
                  onClick={props.clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </Box>
      {props.createButton === "true" && (
        <Box>
          <FormModal />
        </Box>
      )}
    </Box>
  );
}

const HomeTable = ({ createButton, sortData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const homeTable = useSelector(selectHomeTableList);
  const homeTableStatus = useSelector(selectHomeTableStatus);

  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [comicId, setComicId] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [count, setCount] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(!count);
      dispatch(fetchHomeTableList());
      setIsChange(true);
    }, 2 * 60 * 1000);
    return () => clearInterval(interval);
  }, [count]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpenSnackbar(false);
      return;
    }
    setOpenSnackbar(false);
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    if (searchValue === "") {
      if (sortData) {
        const newData = homeTable.filter((el) => el.stage === sortData);
        setRows(newData);
      } else {
        setRows(homeTable);
      }
    } else {
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = rows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field].toString());
        });
      });
      setRows(filteredRows);
    }
  };

  useEffect(() => {
    if (sortData) {
      const newData = homeTable.filter((el) => el.stage === sortData);
      setRows(newData);
    } else {
      setRows(homeTable);
    }
  }, [homeTable, sortData]);

  useEffect(() => {
    if (homeTableStatus !== "loading" && !isChange) {
      setIsLoading(true);
      dispatch(fetchHomeTableList());
      setIsChange(true);
    } else if (homeTableStatus === "succeeded") {
      setIsLoading(false);
    }
  }, [homeTableStatus, isChange, dispatch]);

  const handleExportClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    const currStage = event.row.stage.toLowerCase();
    const currStatus = event.row.status.toLowerCase();
    const currId = event.row.id;
    setComicId(currId);
    if (
      (currStage === "extraction" || currStage === "translation") &&
      (currStatus === "reviewing" || currStatus === "ready for review")
    ) {
      navigate(`/${currStage}/${currId}`);
    } else {
      setOpenSnackbar(true);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "title", headerName: "Title", flex: 1 },
    {
      field: "issue",
      headerName: "Issue",
      flex: 1,
    },
    {
      field: "file_name",
      headerName: "File Name",
      type: "string",
      headerAlign: "left",
      align: "left",
      flex: 2,
    },
    {
      field: "volume",
      headerName: "Volume",
      type: "number",
      align: "left",
      headerAlign: "left",
      flex: 0.5,
    },
    // {
    //   field: "episode",
    //   headerName: "Episode",
    //   type: "number",
    //   headerAlign: "left",
    //   align: "left",
    //   flex: 1,
    // },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 0.5,
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      flex: 0.5,
    },
    {
      field: "stage",
      headerName: "Stage",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row: { stage } }) => {
        return (
          <Box
            width="60%"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              stage === "Translation"
                ? "#e6effc"
                : stage === "Extraction"
                  ? "#ffe5ee"
                  : "#fff8e7"
            }
            borderRadius="5px"
          >
            <Typography
              color={
                stage === "Translation"
                  ? "#0764E6"
                  : stage === "Extraction"
                    ? "#A00"
                    : "#D5B500"
              }
            >
              {stage}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  if (isLoading) {
    <Loader />;
  } else {
    return (
      <Paper
        sx={{
          borderRadius: "5px",
          padding: "1.5rem",
          height: createButton ? "65vh" : "75vh",
        }}
      >
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2500}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            The file is not ready yet!!
          </Alert>
        </Snackbar>
        <Box
          height="100%"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
          }}
        >
          <DataGrid
            components={{
              Toolbar: QuickSearchToolbar,
            }}
            rows={rows}
            columns={columns}
            density="standard"
            onRowClick={handleClick}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(""),
                createButton: createButton,
              },
            }}
          />
        </Box>
        {open && (
          <ExportPopUp
            state={true}
            handleExportClose={handleExportClose}
            comicId={comicId}
          />
        )}
      </Paper>
    );
  }
};

export default HomeTable;
