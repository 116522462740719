import * as React from "react";
import {
  Box,
  DialogContent,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { downloadPost } from "../api/downloadPost";

const ExportPopUp = ({ state, handleExportClose, comicId }) => {
  const [open, setOpen] = useState(state);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (step) => {
    setIsLoading(true);
    downloadPost(step, comicId).then((res) => {
      setIsLoading(false);
      setOpen(false);
      handleExportClose();
    });
  };
  const handleClose = () => {
    setOpen(false);
    handleExportClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h5" fontWeight="bold">
            Download Results
          </Typography>
        </Box>
      </DialogTitle>
      {!isLoading ? (
        <DialogContent dividers={true}>
          <Stack
            sx={{
              display: "flex",
              pt: 2,
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Button
              onClick={()=>{handleSubmit("extraction")}}
              variant="contained"
              size="medium"
              sx={{ width: "20rem" }}
            >
              Text Extraction Results
            </Button>
            <Button
              onClick={()=>{handleSubmit("translation")}}
              variant="contained"
              size="medium"
              sx={{ width: "20rem" }}
            >
              AI Translation Results
            </Button>
          </Stack>
        </DialogContent>
      ) : (
        <DialogContent dividers={true}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "30vh",
            }}
          >
            <Stack spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Starting Download...
              </Box>
            </Stack>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ExportPopUp;
