import { getDefaultHeaders, setCsrfToken } from "./utils";

export async function translationPatch(data, comicTextId) {
  let headers = await getDefaultHeaders(true, true);
  const requestOptions = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(data),
  };
  const url = `/api/translated-texts/${comicTextId}/`;
  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    if (
      response.status === 403 &&
      jsonResponse.detail.includes("CSRF Failed")
    ) {
      await setCsrfToken();
      return await translationPatch(data, comicTextId);
    }
    return jsonResponse;
  } catch (err) {
    console.log("ERROR 19", err);
    return [];
  }
}
