import { getDefaultHeaders, setCsrfToken } from "./utils";

export async function comicTextCreate(data) {
  let headers = await getDefaultHeaders(true, true);
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  const url = "/api/comic-texts/";
  try {
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    if (response.status === 403 && jsonResponse.detail.includes("CSRF Failed")) {

      await setCsrfToken();
      return await comicTextCreate(data)
    }
    return jsonResponse;
  } catch (err) {
    console.log("ERROR 2", err);
    return [];
  }
}
