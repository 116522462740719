import React, { useEffect, useRef, useState } from "react";
import { IconButton, Paper, Tooltip } from "@mui/material";
import CropFreeOutlinedIcon from "@mui/icons-material/CropFreeOutlined";
import { Stage, Layer, Rect } from "react-konva";
import panzoom from "panzoom";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import {
  selectedTextCardIndex,
  selectedPageCardIndex,
} from "../features/panels/panelsSlice";
import { useSelector } from "react-redux";

const BoundingRect = (props) => {
  const [strokeColor, setStrokeColor] = useState("red");

  return (
    <Rect
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      fill="transparent"
      onClick={props.onClick}
      onMouseEnter={() => setStrokeColor("blue")}
      onMouseLeave={() => setStrokeColor("red")}
      stroke={strokeColor}
      strokeWidth={2}
      style={{ cursor: "pointer" }}
    />
  );
};

const KonvaCanvas = ({
  url,
  panelDims,
  boxes,
  handleBBoxClick,
  crop,
  setCrop,
  // textIndex,
  pageIndex,
}) => {
  // const [canvaImage, setCanvaImage] = useState(null);
  const [rectCoordinates, setRectCoordinates] = useState([]);
  const selectedTextIndex = useSelector(selectedTextCardIndex);
  const selectedPageIndex = useSelector(selectedPageCardIndex);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const containerRef = useRef();
  const aspectRatio = panelDims[0] / panelDims[1];

  useEffect(() => {
    if (
      containerRef.current?.offsetHeight &&
      containerRef.current?.offsetWidth
    ) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, [containerRef]);

  useEffect(() => {
    const factorX = dimensions.width / panelDims[0];
    const factorY = dimensions.height / panelDims[1];
    const coordinates = boxes.map((box) => {
      const [left, top, right, bottom] = box;
      return {
        x: left * factorX,
        y: top * factorY,
        width: (right - left) * factorX,
        height: (bottom - top) * factorY,
      };
    });
    setRectCoordinates(coordinates);
  }, [dimensions]);

  const resetZoom = (event) => {
    event.preventDefault();
    const container = panzoom(containerRef.current);
    container.smoothMoveTo(0, 0);
    container.smoothZoom(0, 0, 1);
  };

  useEffect(() => {
    const container = panzoom(containerRef.current, {
      maxZoom: 3,
      minZoom: 0.5,
    });

    return () => {
      container.dispose();
    };
  }, [containerRef]);

  return (
    <Paper
      elevation={1}
      style={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div
        ref={containerRef}
        style={{
          aspectRatio: aspectRatio,
          maxHeight: aspectRatio > 0.2 ? "100%" : undefined,
          maxWidth: aspectRatio > 0.2 ? "100%" : undefined,
          width: aspectRatio > 0.2 ? undefined : "80%",
          backgroundImage: `url(${url})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          zIndex: "2",
          margin: "auto",
        }}
      >
        {selectedTextIndex !== null && selectedPageIndex === pageIndex ? (
          <ReactCrop
            crop={crop}
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              margin: "auto",
              aspectRatio: panelDims[0] / panelDims[1],
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onChange={(crop, percentCrop) => setCrop(percentCrop)}
          >
            <img style={{ height: "100%", width: "100%" }} src={url} alt="" />
          </ReactCrop>
        ) : (
          <Stage width={dimensions.width} height={dimensions.height}>
            <Layer width={dimensions.width} height={dimensions.height}>
              {rectCoordinates?.map((rect, index) => {
                return (
                  <BoundingRect
                    x={rect.x}
                    y={rect.y}
                    width={rect.width}
                    height={rect.height}
                    onClick={() => handleBBoxClick(index)}
                  />
                );
              })}
            </Layer>
          </Stage>
        )}
      </div>
      <Tooltip title="Reset Zoom">
        <IconButton
          aria-label="delete"
          size="small"
          sx={{
            bgColor: "rgba(0,0,0,0.5)",
            top: 10,
            right: 10,
            position: "absolute",
          }}
          onClick={resetZoom}
        >
          <CropFreeOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Paper>
  );
};

export default KonvaCanvas;
