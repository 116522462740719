import { createSlice } from "@reduxjs/toolkit";

const selectedPageSlice = createSlice({
  name: "selectedPage",
  initialState: {
    page: "Home",
  },
  reducers: {
    selectPage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const selectMainPage = (state) => state.selectedPage.page;
export const { selectPage } = selectedPageSlice.actions;
export default selectedPageSlice.reducer;